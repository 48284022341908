<template>
    <div class="mt-1">

        <!-- <div class="text-filter" v-if="housing.length">
        <strong>{{ textFilter }}</strong>
        </div> -->

        <div class="actions-results">

            <div class="actions-filter" >
                <b-form-group label="Numero de registros" v-show="housing.length > 0">
                    <v-select
                        v-model="porPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        @input="changePerPage"
                    />
                </b-form-group>

                <!-- <b-form-group label="Buscar en tabla" v-show="housing.length > 0" >
                    <b-form-input
                        v-model="filter.queryHousing"
                        placeholder="Buscar..."
                        @input="setFilter"
                        class="input-search-t"
                    />
                </b-form-group> -->

            </div>

            <div class="actions-housing">
                <b  v-show="housing.length > 0">Total de Registros: <b-badge variant="success">  {{ housing.length }} </b-badge> </b>
                <b-button
                    :to="{name: 'create-housing'}"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                >
                  <feather-icon icon="PlusIcon" style="color: white" />
                </b-button>
                <b-dropdown
                    toggle-class="p-0"
                    no-caret
                    right
                    v-show="housing.length > 0"
                >
                    <template #button-content>
                        <b-button variant="primary">
                            <feather-icon icon="MoreHorizontalIcon" style="color: white" />
                        </b-button>
                    </template>

                    <b-dropdown-item @click="exportToExcel">
                        <feather-icon icon="FileTextIcon" /> 
                        <span class="align-middle ml-50"> Exportar a excel</span>
                    </b-dropdown-item>

                    <!-- <b-dropdown-item @click="openModalCuotas">
                        <feather-icon icon="DollarSignIcon" />
                        <span class="align-middle ml-50">Cobro de cuotas </span>
                    </b-dropdown-item> -->

                </b-dropdown>
            </div>

        </div>

        <div v-if="housingData.length && !isSearchingHousing">
            <HousingModalQuotasMassive :periodicity="periodicity"/>

            <b-table
                :items="housingData"
                :fields="tableColumns"
                primary-key="id"
                responsive
                no-headers
                :thead-class="!housing.length ? 'd-none' :''"
            >

                <!-- <template #thead-top="data">
                    <b-tr>
                        <b-th variant="success" >
                            <b-form-group>
                                <b-form-checkbox
                                    v-model="isMarkedAll"
                                    @change="setMarkedAll"
                                >
                                </b-form-checkbox>
                            </b-form-group>
                        </b-th>
                    </b-tr>
                </template>  -->

                <!-- <template #head(isMarked)>
                    <b-form-group>
                        <b-form-checkbox
                            v-model="isMarkedAll"
                            @change="setMarkedAll"
                        >
                        </b-form-checkbox>
                    </b-form-group>
                </template>

                <template #cell(isMarked)="row">
                    <b-form-checkbox
                        v-model="row.item.isMarked"
                        @change="setMarked(row.item)"
                    >
                    </b-form-checkbox>
                </template>          -->


                <template #cell(numberHousing)="row">
                    <b-link :to="{name: 'edit-housing', params:{ id: row.item.id } } " class="edit-h"> {{ row.item.numberHousing ? row.item.numberHousing : 'N/A' }} </b-link>
                </template>

                <template #cell(price)="row">
                    <span class="text-nowrap">
                        {{ row.item.price ? `USD ${isPrice(row.item.price)}` : 'N/A' }}
                    </span>
                </template>

                <template #cell(owner)="row">
                    <div class="ownersDetail">
                        <div class="owners-prev">
                            <p class="p-owner" v-if="!!row.item.owner">{{ row.item.owner.ownerName }} {{ row.item.owner.ownerLastname }} </p>
                            <span v-else>--</span>
                        </div>
                    </div>
                </template>

                <template #cell(coOwners)="row">
                    <div class="ownersDetail">
                        <div class="owners-prev">

                            <p class="p-owner">{{ row.item.coOwners.length > 0 ? infoOwner(row.item.coOwners, 0).name : '--' }}  </p>
                            <!-- <b class="text-strong">{{ row.item.coOwners.length > 0 ? infoOwner(row.item.coOwners, 0).nameRelation : ''  }}</b> -->
                        </div>
                        <div>
                            <b-button
                                variant="primary"
                                class="btn-icon"
                                :id="'popover-btn'+row.item.id"
                                size="sm"
                                v-show="row.item.coOwners.length > 1">
                                <feather-icon icon="InfoIcon" size="16"/>
                            </b-button>
                            <b-popover :target="'popover-btn'+row.item.id" variant="primary" triggers="focus">
                                <template #title>Co-propietarios</template>
                                <div class="owners-prev" v-for="(owner, index) in row.item.coOwners" :key="index">
                                    <p class="p-owner">{{ row.item.coOwners.length > 0 ? infoOwner(row.item.coOwners, index).name : ''  }}  </p>
                                    <b class="text-strong">{{ row.item.coOwners.length > 0 ? infoOwner(row.item.coOwners, index).nameRelation : ''  }}</b>
                                </div>
                            </b-popover>
                        </div>
                    </div>
                </template>

                <template #cell(percentage)="row">
                    <span class="text-nowrap">
                        {{ row.item.percentage ? `${formatMoney({value: row.item.percentage, item: row.item}) } %` : 'N/A' }}
                    </span>
                </template>

                <template #cell(status)="row">
                    <span class="text-nowrap">
                        <b-badge pill :variant="row.item.status ? 'success': 'danger'" >
                        {{ row.item.status ? "Activo" : "Desactivado" }}
                        </b-badge>
                    </span>
                </template>

            </b-table>

            <PaginationTable
                v-show="housing.length > 0"
                :currentPage="currentPage"
                :totalRows="housing.length"
                :perPage="porPage"
                @change-page="changePage"
            />
        </div>

        <div class="isCentered isSpinner text-center" v-if="isSearchingHousing">
            <b-spinner class="isCentered" label="Spinning"></b-spinner> <br>
            Cargando viviendas
        </div>
        <div class="" v-else-if="!housingData.length && !isSearchingHousing">
            <b-alert variant="warning" :show="true" class="mb-0">
                <div class="alert-body"> <feather-icon icon="InfoIcon" class="mr-50" />
                    <span>Haga una busqueda mediante el filtro</span>
                </div>
            </b-alert>
        </div>

    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import PaginationTable from '@/modules/fivesClub/components/catalogs/housing/PaginationTable'
import HousingModalQuotasMassive from '@/modules/fivesClub/components/catalogs/housing/HousingModalQuotasMassive'

import { showAlertMessage, toCurrency } from "@/helpers/helpers";

export default {
    directives: {Ripple, },
    props:{
        isSearchingHousing: {
            type: Boolean,
            required: true,
        },
        isSuccessfulSearch: {
            type: Boolean,
            required: true,
        },
    },
    components: { vSelect, PaginationTable, HousingModalQuotasMassive},
    data(){
        return {
            porPage: 10,
            // perPage: 10,
            currentPage: 1,
            perPageOptions: [1, 5, 10, 15, 20, 25, 50, 100],
            queryStringTable: '',
            tableColumns: [
                // { key: 'condosname', label: 'Condominio' },
                // { key: 'isMarked', label: '--', class: 'text-center', thStyle: { width: "50px" }  },
                // { key: 'subcondosname', label: 'Subcondominio' },

                { key: 'numberHousing', label: 'Número de vivienda',class: 'text-center', thStyle: { width: "150px" } },
                { key: 'contract', label: 'Contrato activo',class: 'text-center',  },
                { key: 'owner', label: 'Propietario',class: 'text-justify',  thStyle: { width: "300px" }},
                { key: 'coOwners', label: 'Co-Propietarios',class: 'text-justify',  thStyle: { width: "300px" }},

                { key: 'categoryroomsname', label: 'Habitación',class: 'text-center',  },
                { key: 'price', label: 'Precio' },
                { key: 'percentage', label: 'Porcentaje' ,class: 'text-center',
                    formatter: (value, key, item) => {return this.formatMoney({value, item}) }
                },

                { key: 'points', label: 'Puntos',class: 'text-center',  },
            ],
			periodicity: null,

        }
    },
    computed: {
        ...mapState('fivesClubCatalogs', ['housing','filter', 'isMarkedAllHousing', 'requestHousing', 'condos', 'subCondos', 'rentalPool']),
        ...mapState('start', ['hotels']),
        ...mapState("groups", ["roomsTypes"]),
        housingData(){
            if (this.housing?.length){
                return this.housing.slice( (this.currentPage - 1) * this.porPage, this.currentPage * this.porPage )
            } else return []
        },
        numberMarkedItems(){
            return  this.housing.filter( h => h.isMarked ).length
        },
        textFilter(){
            let text = ``
            if(this.requestHousing){
                text = `Resultados de la búsqueda: `
                const { Number, IdCondo, IdSubCondo, activeResidences, resort, roomType, rentalPoolId, paymentTypeId, balanceDue } = this.requestHousing
                const condo = IdCondo ? this.condos.find(item => item.id === IdCondo) : null
                const subCondo = IdCondo ? this.subCondos.find(item => item.id === IdSubCondo) : null
                const rentalPool = rentalPoolId ? this.rentalPool.find(item => item.id === rentalPoolId) : null
                const hotel = resort ? this.hotels.find(item => item.id === resort) : null
                const roomTypeF = resort ? this.roomsTypes?.find(item => item.id === roomType) : null
                text += `${( activeResidences  == '1' ) ? ' Viviendas activas' :  ' Viviendas inactivas' }`
                text += rentalPool ? ` del Pool de Rentas ${rentalPool?.name}` : ''
                text += condo ? `, del Condominio ${condo?.name}` : ''
                text += subCondo ? `, del SubCondominio ${subCondo?.name}` : ''
                text += Number ? `, Vivienda Número ${Number}` : ''
                text += hotel ? `, del Resort ${hotel?.name}` : ''
                text += roomType ? ` y con Tipo de Habitación  ${roomTypeF?.name}` : ''
            }
            return text
        },
        isMarkedAll: {
            get(){
                return this.isMarkedAllHousing
            },
            set(isMarked) {
                this.setIsMarkedAllHousing(isMarked)
            }
        }
    },
    methods:{
        ...mapActions('fivesClubCatalogs', ['fetchHousingReport','fetchCondoFeeDetail']),
        ...mapMutations('fivesClubCatalogs',['setMarkedHousing','setAllMarkedHousing','setIsMarkedAllHousing']),
        isPrice(amount){
            const formated = toCurrency(amount)
            return formated
        },
        // le paso un arreglo de owner y extraigo la info de acuerdo al indice que le indico
        infoOwner(owners, index){
            const relation = {
                1: 'Propietario',
                2: 'Familiar',
                3: 'Co-Propietario',
                4: 'Comunidad de bienes'
            }
            const name = `${owners[index].ownerName} ${owners[index].ownerLastname}`
            const nameRelation = relation[owners[index].relation]

            return { name, nameRelation }
        },
        formatMoney(data){
            const {value, item} = data
            return toCurrency(value, item?.currency?.code)

        },
        setFilter(e){
            console.log(e)
        },
        changePerPage( perPage ){
            this.perPage = perPage
        },
        changePage(page){
            this.currentPage = page
        },
        setMarked(item){
            const {id, isMarked } = item
            this.setMarkedHousing({id,isMarked})
        },
        setMarkedAll(isMarked){
            this.setAllMarkedHousing({ isMarked })
            this.setIsMarkedAllHousing(isMarked)
        },
        async exportToExcel(){
            const idCondo = this.requestHousing && this.requestHousing.IdCondo
            const statusHouse = this.requestHousing && parseInt(this.requestHousing.activeResidences)
            const status = statusHouse ? parseInt(statusHouse) : 1

            if( idCondo ){
                await this.fetchHousingReport({idCondo, status})
            }

            if( !idCondo ){
                showAlertMessage("Filtre por Condominio", "InfoIcon", 'Debe hacer el filtrado de viviendas por condominio', "danger", 4000, "bottom-right" )
                return
            }
        },
        async openModalCuotas(){
            const idCondo = this.requestHousing && this.requestHousing.IdCondo

			this.periodicity = null

            if( this.numberMarkedItems == 0 ){
                showAlertMessage("Seleccione viviendas", "InfoIcon", 'Debe seleccionar al menos una vivienda', "danger", 4000, "bottom-right" )
                return
            }

            if( !idCondo ){
                showAlertMessage("Condominio para esta opción", "InfoIcon", 'Para esta opción debe tener filtrado las viviendas por Condominios', "danger", 4000, "bottom-right" )
                return
            }

            this.$root.$emit("bv::show::modal", 'modal-quotas-housing-massive')
            const { status, data } = await this.fetchCondoFeeDetail(idCondo)
            if(status && data.length > 0){
			    this.periodicity = { shorthand: data[0].periodicity, months: data[0].monthsapplypayment, idCondo: data[0].id}
            }
        }

    }
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped >

.actions-results{
    display: flex;
    flex-direction: column-reverse;
    align-items:center;
    margin-bottom: 1rem;
    gap: 1rem;
}

.text-filter{
    display: flex;
    justify-content: flex-end;
}

.actions-housing{
    display: flex;
    justify-content: space-between;
    align-items:center;
    gap: 1rem;
}

.input-search-t{
    min-width: 240px;
    width: 350px;
}
@media (min-width:768px) {
    .actions-filter{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
    }
}

@media (min-width:1024px) {
    .actions-results{
        flex-direction: row;
        justify-content: space-between;
    }
}

.edit-h{
    font-weight: bold;
}

.ownersDetail{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.owners-prev p{
    margin: 0;
}
.p-owner{
    word-break: break-all;
  white-space: pre-wrap;
}
</style>